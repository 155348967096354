<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product/contest' }">赛事列表</el-breadcrumb-item>
                <el-breadcrumb-item>赛事详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <div style="height: 20px;"></div>
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" ref="form" :model="form" :label-width="this.env.label_width">
                        <!-- 赛事名称 -->
                        <el-form-item label="赛事名称" prop="activity_title">
                            <span class="form-span-text">{{ form.activity_title }}</span>
                        </el-form-item>
                        <!-- 运动品类  -->
                        <el-form-item label="运动品类">
                            <el-tag :key="item.index" v-for="item in form.motion_category_two" class="form-tag_colour-tag">
                                {{ item.name }}
                            </el-tag>
                        </el-form-item>
                        <!-- 运动效果 -->
                        <el-form-item label="运动效果">
                            <el-tag :key="item.index" v-for="item in form.target_category_zero" class="form-tag_colour-tag">
                                {{ item.name }}
                            </el-tag>
                        </el-form-item>
                        <!-- 赛事类型 -->
                        <el-form-item label="赛事类型" prop="contest_type">
                            <el-tag class="form-tag_colour-tag">{{ form.contest_type === 1 ? '个人赛' : '团体赛' }}</el-tag>
                        </el-form-item>
                        <!-- 人数限制 -->
                        <el-form-item v-if="form.contest_type === 1">
                            <span class="form-span-text">{{ form.quota }}</span>
                        </el-form-item>
                        <template v-else-if="form.contest_type === 2">
                            <el-form-item label="团队数限制" prop="quota">
                                <span class="form-span-text">{{ form.quota }}</span>
                            </el-form-item>
                            <el-form-item label="团队人数限制" prop="quota">
                                <el-tag>{{ form.contest_json.team_limit === 0 ? '否' : '是' }}</el-tag>
                                <p v-if="form.contest_json.team_limit === 1" class="form-span-text">限制每个团队只能报名{{
                                    form.contest_json.team_quota_limit
                                }}人</p>
                            </el-form-item>
                        </template>
                        <!-- 赛事地址 -->
                        <el-form-item label="活动地区">
                            <span class="form-span-text">{{ `${form.province}${form.city}${form.county}` }}</span>
                        </el-form-item>
                        <el-form-item label="详细地址">
                            <span class="form-span-text">{{ form.address }}</span>
                        </el-form-item>
                        <!-- 联系方式 -->
                        <el-form-item label="联系方式">
                            <span class="form-span-text">{{ form.activity_phone }}</span>
                        </el-form-item>
                        <!-- 关联门店 -->
                        <el-form-item label="指定门店">
                            <span class="form-span-text">{{ form.store_uuid_all.length > 0 ?
                                form.store_uuid_all[0].store_name : '' }}</span>
                        </el-form-item>
                        <!-- 主办方 -->
                        <el-form-item label="主办方">
                            <span class="form-span-text">{{ form.sponsor_name ? form.sponsor_name : '未设置' }}</span>
                        </el-form-item>
                        <!-- 承办方 -->
                        <el-form-item label="承办方">
                            <span class="form-span-text">{{ form.organizer_name ? form.organizer_name : '未设置' }}</span>
                        </el-form-item>
                        <!-- 报名时间 -->
                        <el-form-item label="报名时间">
                            <span class="form-span-text">{{ `${form.signup_start_time} - ${form.signup_end_time}` }}</span>
                        </el-form-item>
                        <!-- 赛事时间 -->
                        <el-form-item label="赛事时间">
                            <span class="form-span-text">{{ form.time_type === 1 || form.time_type === 2 ? '固定时间' : '持续时间'
                            }}</span>
                            <template v-if="form.time_type === 1 || form.time_type === 2">
                                <div v-for="(item, index) in this.form.detailed_time" :key="index" style="display: flex;">
                                    <span style="width: 60px;">第{{ index + 1 }}天：</span>
                                    <div>
                                        <p style="margin: 0;" class="form-span-text">{{ item.activity_date }}</p>
                                        <p style="margin: 0;" class="form-span-text">{{ `${item.activity_time[0]} -
                                                                                    ${item.activity_time[1]}` }}
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <div v-else>
                                <span class="form-span-text">{{ `${form.start_time} - ${form.end_time}` }}</span>
                            </div>
                        </el-form-item>
                        <!-- 上传封面 -->
                        <el-form-item label="赛事封面" prop="cover">
                            <Qnupload v-model="form.cover" :isclipper="true" :compress="false" :fixedNumber="[4, 3]"
                                :readonly='true' />
                        </el-form-item>
                        <!-- 赛事照片 -->
                        <el-form-item label="赛事照片" prop="photo">
                            <Qnupload v-model="form.photo" :sum="5" :isclipper="true" :compress="false"
                                :fixedNumber="[25, 28]" :readonly='true' />
                        </el-form-item>
                        <!-- 报名费 -->
                        <el-form-item label="售价" prop="price">
                            ￥<span class="form-span-text">{{ form.price }}</span>
                        </el-form-item>
                        <!-- 退款规则 -->
                        <el-form-item label="退款规则" prop="is_refund">
                            <span class="form-span-text">{{ form.is_refund === 0 ? '不允许退款' : '允许退款' }}</span>
                            <p class="form-span-text" v-if="form.is_refund === 1">
                                <span>{{ form.is_refund_end_time }}</span> <span style="margin-left: 10px;">前可退款</span>
                            </p>
                        </el-form-item>
                        <!-- 注意事项 -->
                        <el-form-item label="注意事项" prop="attention">
                            <span class="form-span-text">{{ form.attention }}</span>
                        </el-form-item>
                        <!-- 详情 -->
                        <el-form-item label="详情" prop="details_rich_text">
                            <!-- 富文本编辑框 -->
                            <div v-html="form.details_rich_text" class="rich-text__container" />
                        </el-form-item>
                        <el-form-item>
                            <el-button size="medium" @click="isreturn">返回
                            </el-button>

                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'
export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            page_name: '赛事详情',
            activity_uuid: '',              // 赛事uuid
            loading: false,
            // 表单内容
            form: {
                store_uuid_all: [],
            },
        }
    },
    // 创建
    created() {
        this.loading = false
        this.init()
    },
    methods: {
        // 初始化
        init() {
            let activity_uuid = this.$route.query.activity_uuid
            if (!this.Tool.is_empty(activity_uuid)) {
                this.activity_uuid = activity_uuid
                this.getinfo(activity_uuid)
            } else {
                this.loading = false
            }
        },
        //获取详情
        getinfo(activity_uuid) {
            let postdata = {
                api_name: "product.contest.getinfo",
                token: this.Tool.get_l_cache('token'),
                activity_uuid
            }
            // console.log('postdata', postdata)
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.push({ path: '/product/contest' })
        },
    }
}
</script>

<style  scoped>
>>>p {
    margin: 0;
}
</style>
